<template>
  <content-with-sidebar
    v-if="newsData"
    class="cws-container cws-sidebar-right blog-wrapper"
  >

    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="blog-content"
            v-html="newsData"
          />
          <!-- eslint-enable -->
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->
    <!-- sidebar -->
    <div
      slot="sidebar"
      class="news-sidebar py-2 py-lg-0"
    >
      <!-- <div
        class="d-flex justify-content-end mb-2"
      >
        <b-button
          :to="{ name: 'apps-news-add' }"
          variant="primary"
        >
          {{ $t('Add') }}
        </b-button>
      </div> -->
      <!-- <b-form-group
        label="Статус"
        label-for="news-status"
        class="mb-2"
      >
        <v-select
          id="news-status"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :reduce="val => val.value"
          :options="newsStatuses"
          :value="xparam1"
          :filterable="false"
          :clearable="false"
          @input="(val) => {
            xparam1 = val
          }"
        />
      </b-form-group> -->
      <!-- recent posts -->
      <div
        v-if="newsSidebar.recentPosts && newsSidebar.recentPosts.length"
        class="blog-recent-posts"
      >
        <h6 class="section-label mb-75">
          Недавние посты
        </h6>
        <b-media
          v-for="(recentpost, index) in newsSidebar.recentPosts"
          :key="recentpost.img"
          no-body
          :class="index? 'mt-2':''"
        >
          <b-media-aside class="mr-2">
            <b-link
              :to="{ name: 'news-detail', params: { id: recentpost.id_new } }"
            >
              <b-img
                v-if="recentpost.n_imgPreview"
                :src="server + JSON.parse(recentpost.n_imgPreview)[0]"
                width="100"
                rounded
                height="70"
                style="object-fit: cover"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link class="text-body-heading">
                {{ recentpost.n_header }}
              </b-link>
            </h6>
            <span class="text-muted mb-0">
              {{ recentpost.n_date }}
            </span>
          </b-media-body>
        </b-media>
      </div>
      <!--/ recent posts -->
      <!-- categories -->
      <div
        class="news-categories"
        :class="{'mt-3': newsSidebar.recentPosts && newsSidebar.recentPosts.length}"
      >
        <h6 class="section-label mb-1">
          Тип материала
        </h6>

        <div
          v-for="(category, key) in newsSidebar.categories"
          :key="key"
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-link>
            <b-avatar
              rounded
              size="32"
              :variant="category.color"
              class="mr-75"
            >
              <feather-icon
                :icon="category.icon"
                size="16"
              />
            </b-avatar>
          </b-link>
          <b-link
            :to="{ name: 'news', query: { ...$route.query, cat_id: category.value } }"
          >
            <div class="news-category-title text-body">
              {{ category.label }}
            </div>
          </b-link>
        </div>
      </div>
      <!--/ categories -->
      <!-- tags -->
      <!-- <div class="news-tags mt-3">
        <h6 class="section-label mb-1">
          Тип отображения
        </h6>
        <b-link
          v-for="(tag, key) in newsSidebar.tags"
          :key="key"
          class="d-inline-block mb-75"
          @click="setTagId(tag.value)"
        >
          <b-badge
            pill
            class="mr-75"
            :variant="tagsColor(tag.value)"
          >
            {{ tag.label }}
          </b-badge>
        </b-link>
      </div> -->
      <!--/ tags -->
    </div>
    <!--/ sidebar -->
  </content-with-sidebar>
</template>

<script>
import {
  // BCard,
  BRow, BCol, BAvatar, BLink,
  BMedia, BMediaAside, BImg, BMediaBody,
} from 'bootstrap-vue'
import { useRouter } from '@core/utils/utils'
import { ref, watch, onUnmounted } from '@vue/composition-api'
import { kFormatter, avatarText } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import router from '@/router'
import store from '@/store'
import newsAndDocumentsStoreModule from '../../newsAndDocumentsStoreModule'
import useNewsList from '../news-list/useNewsList'

export default {
  components: {
    // BCard,
    BRow,
    BCol,
    BAvatar,
    BLink,
    ContentWithSidebar,
    BMedia,
    BMediaAside,
    BImg,
    BMediaBody,
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Standart') return 'light-primary'
      if (tag === 'Slider') return 'light-success'
      if (tag === 'video') return 'light-warning'
      return 'light-primary'
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-news'
    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, newsAndDocumentsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const { route } = useRouter()

    const {
      newsSidebar,
      fetchRecentNews,
    } = useNewsList()

    const server = process.env.VUE_APP_SERVER

    const newsData = ref(null)

    const fetchNewsItem = () => {
      store.dispatch('app-news/fetchNewsItem', { id: router.currentRoute.params.id })
        .then(response => {
          newsData.value = response.data

          fetchRecentNews()
        })
        .catch(error => {
          if (error.response.status === 404) {
            newsData.value = undefined
          }
        })
    }

    watch([route], () => {
      fetchNewsItem()
    })

    fetchNewsItem()

    return {
      server,
      newsData,
      avatarText,
      newsSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
